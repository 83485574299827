import './style.css';
import './introjs.min.css';
import Main from './Main';
function App() {
  return (
    <Main />
  );
}

export default App;
