import { Switch, Route } from 'react-router-dom';

import Auditoria from './pages/auditorias';
import Convocatoria from './pages/convocatoria';
import Licitaciones from './pages/licitaciones';
import ContratacionesMenores from './pages/contrataciones-menores';


const Main = () => {

  return (
    <Switch> {/* The Switch decides which component to show based on the current URL.*/}
    <Route exact path='/' component={ContratacionesMenores}></Route>
      {/* <Route exact path='/' component={Auditoria}></Route> */}
      {/* <Route exact path='/auditoria' component={Auditoria}></Route>
      <Route exact path='/licitaciones' component={Licitaciones}></Route> */}
    </Switch>
  );
}

export default Main;