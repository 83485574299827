import axios from 'axios';
class Servicos {
    constructor() {
        this.url = process.env.REACT_APP_URL;
    }

    obtenerDatos = async (endPoint) => {
        try {
            const res = await axios.get(`${this.url}/${endPoint}`);
            if (res.status === 200) {
                return res.data.data;
            } else {
                return { error: 'ocurrio un problema' }
            }
        } catch (error) {
            console.log(error);
        }
    }
}

const servicios = new Servicos();
export default servicios;