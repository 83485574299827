import Footer from './Footer';
import logo from '../image/logo.png';

export default function Layaut(props) {
    return (
        <div className="container-padre">
            <div className="img">
                <img src={logo} alt="" />
            </div>

            <div className="container">
                {props.children}
            </div>

            <Footer />
        </div>

    );
}